import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useStaticQuery, graphql } from 'gatsby'
import ogImage from '../../static/og/logo.png'

const SEO = ({ title, description, article }) => {
    const { pathname } = useLocation()
    const { site } = useStaticQuery(query)

    const {
        defaultTitle,
        titleTemplate,
        defaultDescription,
        siteUrl,
        twitterUsername,
    } = site.siteMetadata

    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        url: `${siteUrl}${pathname}`,
    }

    return (
        <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <meta name='description' content={seo.description} />
            <meta name='robots' content='index, follow' />
            {(article ? true : null) && <meta property='og:type' content='article' />}

            {seo.url && <meta property='og:url' content={seo.url} />}
            {seo.description && <meta property='og:description' content={seo.description} />}
            {seo.title && <meta property='og:title' content={seo.title} />}
            <meta property='og:image' content={ogImage} />
            <meta property='og:locale' content='en_US' />
            <meta property='og:type' content='website' />

            <meta name='twitter:card' content='summary_large_image' />
            {seo.title && <meta name='twitter:title' content={seo.title} />}
            {seo.description && <meta name='twitter:description' content={seo.description} />}
            {twitterUsername && <meta name='twitter:creator' content={twitterUsername} />}
            {seo.url && <meta property='twitter:url' content={seo.url} />}
            <meta property='twitter:image' content={ogImage} />
        </Helmet>
    )
}

export default SEO

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl: url
                twitterUsername
            }
        }
    }
`
